$(document).ready(function() {
    $('#list-events').owlCarousel({
        items: 1,
        nav: true,
        navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
        dots: false,
        autoplay:true,
        autoplayTimeout:5000,
        autoplaySpeed: 700,
        autoplayHoverPause:true,
        smartSpeed: 700
    });

    $('#header-top .navbar-nav a').on('click', function() {
        var href = $(this).attr('href');

        if (href == '#book-table') {
            $('#forms-wrapper-section #form-headers a[href="#formulaire-1"]').tab('show');

            $('html, body').animate({scrollTop: $('#forms-wrapper-section').offset().top - 30},'slow');
        } else if (href == '#contact-us') {
            $('#forms-wrapper-section #form-headers a[href="#formulaire-2"]').tab('show');

            $('html, body').animate({scrollTop: $('#forms-wrapper-section').offset().top - 30},'slow');
        } else if (href == '#r-club') {
            $('html, body').animate({scrollTop: $('#r-club-section').offset().top},'slow');
        }

        return false;
    });
});